function map_change_slug(n_id) {
  var new_id;
  new_id = n_id.replace('_active', '').replaceAll("_","-");

  return new_id;
}

jQuery(document).ready(function($) {
  //dropdown on mobile select
  $( ".sobewff-event-select" ).click(function() {
    $(".sobewff-event-select").toggleClass("active");
  });

  //wait for element to exist on page load
  /*
  if(!isMobile){
    waitForElementToExist('#south_beach_active').then(element => {
      jQuery('#south_beach_active').click();
    });
  }
  */


  const neighborhoods = document.querySelectorAll(".sobewff-event-map #neighborhoods_active path, .sobewff-event-map #neighborhoods_active polygon, .switch");
  const mob_labels = document.querySelectorAll(".sobewff-event-map #labels *");
  var n_id;
  var new_id;
  var map_slug;
  var map_slug_nice;
  var label_sel;
  var this_id;
  var this_neighborhood;
  var push_data = "";

  $( neighborhoods, '.sobewff-event-select .switch' ).on( "click", function(event) {

    event.preventDefault();
    n_id = $(this).attr('id');
    map_slug = map_change_slug(n_id);
    map_slug_nice = map_slug.replaceAll("-"," ").replaceAll("8","&");
    var mobile_neighborhood_slug = "";

    var push_slug = "mobile-"+map_slug_nice;
    var offsety=25;
    if(!isMobile) {
       push_slug = map_slug;
       offsety=75;
    }
    push_data = {
      event: "mob_track_click",
      mob_track_category: "Home Map",
      mob_track_action: "Click",
      mob_track_label: push_slug
    };

    window.dataLayer = window.dataLayer || [];
    dataLayer.push(push_data);


    /*
    jQuery('html, body').stop().animate({
        scrollTop: jQuery(".sobewff-event-map").offset().top - offsety
    }, 500, 'linear');
    */


    //get neighborhood name for mobile
    var mobile_neighborhood_name = $(this).attr("data-name");
    mobile_neighborhood_slug = $(this).attr("id");

    if (typeof mobile_neighborhood_name !== 'undefined' && mobile_neighborhood_name !== false) {
        document.querySelector('.sob-select-input').value = mobile_neighborhood_name;
    }
    else {
      document.querySelector('.sob-select-input').value = map_slug_nice;
    }

    //fade out any events showing
    //jQuery('#map-events').animate({ opacity: 0 }, "fast");
    $('#map-events').removeClass('active');

    //remove current active
    for (i = 0; i < neighborhoods.length; i++){
        neighborhoods[i].classList.remove("click");
    }

    //remove current active
    for (i = 0; i < mob_labels.length; i++){
        mob_labels[i].classList.remove("active");
    }

    //activate map item
    $(this).addClass("click");
    $("#neighborhoods_active #"+mobile_neighborhood_slug+"_active").addClass("click");


    //activate map label
    label_sel = n_id.replace("_active", "");
    $("#"+label_sel+"_label").fadeIn(300).delay(300).addClass("active");

    jQuery.ajax({
      url: mobfm_ajax.ajax_url,
      type: 'post',
      data: {
        action: 'mob_get_map_events',
        slug: map_slug
      },
      beforeSend:function(){
        //$(".map-loading").fadeIn(700);
      },
      success: function( html ) {
        //$(".map-loading").fadeOut(100);
        $('.map-event-items').html( html );
        $('#map-events').fadeIn(300).delay(300).addClass('active');
        $('#map-events .map-events-header .neighborhood-title').html(map_slug_nice);
      }
    });

  });

  $('#map-events .map-events-header .icon-close').on( "click", function(event) {
    $('#map-events').removeClass('active').fadeOut(300);

    //remove current active
    for (i = 0; i < neighborhoods.length; i++){
        neighborhoods[i].classList.remove("click");
    }
    //remove current active
    for (i = 0; i < mob_labels.length; i++){
        mob_labels[i].classList.remove("active");
    }

    //remove active selection from mobile dropddown

  });

  function hasTouch() {
    return 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  }

  if(!hasTouch()){
    neighborhoods.forEach(neighborhood => {
       neighborhood.addEventListener("mouseover", function () {
          $(this).addClass("active");
       });

       neighborhood.addEventListener("mouseout", function () {
         $(this).removeClass("active");
       });
    });
  }


  $(document).mouseup(function(m){
    var container = $(".sobewff-event-select");
    if(!container.is(m.target) && container.has(m.target).length === 0){
        $(".sobewff-event-select").removeClass('active');
    }
  });

});
