//----------------------------------------------
//----------------------------------------------
// Mobile MM Menu
// Credit: https://github.com/FrDH/jQuery.mmenu/issues/280
//----------------------------------------------
//----------------------------------------------

jQuery(document).ready(function($) {

  //mobile menu
  $( ".mob-menu-button" ).click(function() {
    $( ".sw-mobile-menu, .sw-mobile-bg, body" ).addClass( "active" );
  });
  $( ".has-sub-menu" ).click(function(e) {
    e.preventDefault();
    $(this).closest( ".has-dropdown" ).find( ".sw-mobile-sub-menu" ).addClass( "active" );
    $( ".sw-mobile-menu" ).css("scrollbar-width", "none");
  });
  $( ".sw-back" ).click(function() {
    $( ".sw-mobile-sub-menu" ).removeClass( "active" );
  });

  $( "#main-navigation .icon-search" ).click(function() {
    $( ".sw-input-search" ).slideToggle( "fast" );
    $( ".sw-input-search form input[name='s']" ).focus();
  });
  $( "#main-navigation .sw-close" ).click(function() {
    $( ".sw-input-search" ).slideToggle( "fast" );
  });

  $( ".sw-mobile-menu .header-logo .icon-close" ).click(function() {
    $(".sw-mobile-menu, .sw-mobile-sub-menu, .sw-mobile-bg, body").removeClass('active');
  });


  $(document).mouseup(function(e){
  var container = $(".sw-mobile-menu");
  if(!container.is(e.target) && container.has(e.target).length === 0){
    $(".sw-mobile-menu, .sw-mobile-sub-menu, .sw-mobile-bg, body").removeClass('active');
  }
  });

  $(document).mouseup(function(m){
  var container = $(".sobewff-event-select");
  if(!container.is(m.target) && container.has(m.target).length === 0){
    $(".sobewff-event-select").removeClass('active');
  }
  });

}); //end document ready
