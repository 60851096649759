/* Global JS */

//Check if is Mobile
var isMobile = false; //initiate as false
// device detection
if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
  || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) { // jshint ignore:line
  isMobile = true;
}

function getDocumentHeight() {
  return Math.max(
    Math.max(document.body.scrollHeight, document.documentElement.scrollHeight),
    Math.max(document.body.offsetHeight, document.documentElement.offsetHeight),
    Math.max(document.body.clientHeight, document.documentElement.clientHeight)
  );
}

function waitForElementToExist(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true,
    });
  });
}



//----------------------------------------------
//----------------------------------------------
// Remove mobile header ad on desktop
// Remove desktop header ad on mobile
//----------------------------------------------
function headerAdvertisements(){
  var mobile_header_ad = jQuery('.mobilefm-header-advertisement');
  desktop_header_ad = jQuery('.mobfm-header-advertisement');

  if (jQuery(window).width() > 768 || jQuery('body').hasClass('home')) {
    mobile_header_ad.remove();
  }
  else {
    desktop_header_ad.remove();
  }
}

function result() {
  let URL = window.location.pathname;
  let page = URL.split("/").pop().split(".").shift();
  document.querySelector('body').classList.add(`pg-${page}`);
}

//----------------------------------------------
//----------------------------------------------
// Cart Quantity
//----------------------------------------------
//----------------------------------------------
function displayCartQuantity(cartQuantity) {

  if(cartQuantity > 0 && cartQuantity != undefined) { // jshint ignore:line
    jQuery('.badge-info').html(cartQuantity);
    jQuery('.badge-info').fadeIn("fast");
  }
  else {
    jQuery('.badge-info').fadeOut("fast");
  }
}

function checkCartQuantity(callback) {
  var cartQuantity = Number(localStorage.getItem("cartQuantity"));
  callback(cartQuantity);
}

function clearCartQuantity() {
  var currentCartQuantityExpiration = Number(localStorage.getItem("cartQuantityExpiration"));
  var now = new Date().getTime();
  var cartQuantity = Number(localStorage.getItem("cartQuantity"));

  if(now > currentCartQuantityExpiration || cartQuantity===0) {
    //now is larger than the expiration - we should clear it
    localStorage.setItem("cartQuantity", 0);
    displayCartQuantity(0);
    localStorage.removeItem("cartQuantityExpiration");
  }
}//clearCartQuantity

document.addEventListener("shoppingCartQuantityUpdated", function (e) {
  var currentCartQuantity = Number(localStorage.getItem("cartQuantity"));

  var shoppingCartQuantity = e.detail;
  localStorage.setItem("cartQuantity", shoppingCartQuantity);
  if(currentCartQuantity===0){
    localStorage.setItem("cartQuantityExpiration", new Date().getTime()+ 21*60000);
  }
  if(shoppingCartQuantity===0) {
    localStorage.removeItem("cartQuantityExpiration");
  }
  displayCartQuantity(shoppingCartQuantity);
}, false);


jQuery(document).ready(function($) {
  headerAdvertisements();
  AOS.init({ disable: 'mobile' });
  clearCartQuantity();
  //Setup Menu for multiple columns & days
  jQuery('.menu-day').wrapAll('<div class="mob-menu-day-wrap">');
  jQuery('.menu-event').wrapAll('<div class="mob-menu-event-wrap">');

  //cart quantity
  checkCartQuantity(function(shoppingCartQuantity) {
    displayCartQuantity(shoppingCartQuantity);
  });

  //Cookie Consent
  if(!Cookies.get('mob-cookie-consent')){
    jQuery('.mob-cookie-consent').addClass("fadeInBottom-mob");
  }
  else {
    jQuery('.mob-cookie-consent').hide();
  }
  jQuery(document).on('click', '.mob-cookie-consent .btn', function (e) {
    e.preventDefault();
    Cookies.set('mob-cookie-consent', 'dismiss', { expires: 365 });
    jQuery(".mob-cookie-consent").animate({bottom: "-500px"}).delay(500).fadeOut(500);
  });

  jQuery(document).on('click', '.mob-cookie-consent button', function (e) {
    e.preventDefault();
    jQuery(".mob-cookie-consent").animate({bottom: "-500px"}).delay(500).fadeOut(500);
  });

  //scrollto class
  jQuery(document).on('click', '.mobscrollto', function (e) {
    e.preventDefault();
    jQuery('html, body').stop().animate({
        scrollTop: jQuery(jQuery(this).attr('href')).offset().top - 100
    }, 1250, 'linear');
  });

  //hash scroll
  if(window.location.hash) {
      var offset = -100;
      // smooth scroll to the anchor id
      $('html, body').delay(500).animate({
          scrollTop: ($(window.location.hash).offset().top + offset) + 'px'
      }, 1000, 'swing');
  }

  var ad_slider = jQuery('.mobfm-ad-slider');


  ad_slider.on('init beforeChange', function(event, slick, currentSlide, nextSlide){
    var myslide;

    if(event.type==="init"){

      ad_slider.css({
         'visibility' : 'visible'
      }).fadeTo(750, 1);

      myslide = currentSlide;
      jQuery(".mobfm-header-advertisement-close").delay(900).fadeTo(250,1);
    }
    else {
      myslide = nextSlide;
    }

    var slide_link = jQuery(slick.$slides.get(myslide)).find('a'),
    category = slide_link.data('category'),
    label = slide_link.data('label');

    data = {
      'event':'AdImpression',
      'label': category + ": " + label
    };

    //console.log("Push Data Layer: " + JSON.stringify(data));
    window.dataLayer = window.dataLayer || [];
    dataLayer.push (data);
  });

  $('[data-toggle="tooltip"]').tooltip({
      trigger : 'hover'
  });


  ad_slider.slick({
    accessibility: true,
    //focusOnChange: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 700,
    autoplay: false,
    autoplaySpeed: 8000,
    fade: true,
    cssEase: 'ease-in-out',
    pauseOnHover: false,
    pauseOnFocus: false,
    rows: 0
  });


  //Closes the header advertisement
  jQuery(".mobfm-header-advertisement-close").on('click', function(e) {
    e.preventDefault();
    jQuery('.mobfm-header-advertisement').fadeTo(500, 0, function(){
      jQuery('.mobfm-header-advertisement').css("visibility", "hidden");
    });
  });


  // Squadup Ticketing Bootstrap Modal
  // Destroy the embed object anytime the modal is hidden
  if (jQuery(window).width() > 768) {
    jQuery('#ticket_popup').on('hide.bs.modal', function (e) {
        document.dispatchEvent(new CustomEvent('destroySquadupEmbed'));
    });
  }

}); //end document ready

var processing,
    scrollDown,
    mow,
    ih,
    show_value;
var leaf_breakpoint = 6000;
///*****Bottom up scroll & add palms****////
jQuery(window).scroll(function() {
  //if ih is mobile it will be around 600px tall
  //we want to show back to top around 1000px tall
  if (processing) {
    return false;
  }
  ih = window.innerHeight;
  show_value = ih*2.25;
  if(jQuery(document).height() < show_value){
    show_value = 750;
  }
	if (jQuery(window).scrollTop() > show_value) {
		jQuery("#topcontrol").addClass('top-bottom');
	}
	else {
		jQuery("#topcontrol").removeClass('top-bottom');
	}

  scrollDown = jQuery(window).scrollTop();
  mow = jQuery(".mobfm-wrap-outer").outerHeight();
  //console.log(scrollDown + ">=" + mow);
  if (scrollDown >= leaf_breakpoint) {
     //console.log("add palms" + leaf_breakpoint + " MOW: " + mow);
     jQuery(".mob-palms .mob-palms-left").height(mow);
     jQuery(".mob-palms .mob-palms-right").height(mow);
     leaf_breakpoint += 3000;
  }
});





jQuery('#topcontrol').click(function(){
	jQuery("html, body").animate({scrollTop: 0},1000);
});


///--------Show search on mobile after click------////
if (jQuery(window).width() < 991) {
  // To show search on responsive menu
  jQuery('.mobfm-nav-search').click(function(){
		jQuery('.mobfm-nav-searchbox-wrapper').show();
    jQuery('.mobfm-nav-searchbox-input').focus();
  });
  jQuery('.mobfm-nav-searchbox-close').click(function(){
		jQuery('.mobfm-nav-searchbox-wrapper').hide();
  });
}
else {
  jQuery(".sw-mobile-menu").remove();
}




/* jshint ignore:start */

(function() {
    function getParameterByName(name) {
        url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    var promo = getParameterByName('squadup-promo')
    if (promo) {
        sessionStorage.setItem('squadup-promo', promo)
    }
})()
/* jshint ignore:end */




function close_accordion_section() {
	jQuery('.mobfm-accordion .mobfm-accordion-section-title').removeClass('active');
	jQuery('.mobfm-accordion .mobfm-accordion-section-content').slideUp(300).removeClass('open');
}

jQuery('.mobfm-accordion-section-title').click(function(e) {
	// Grab current anchor value
	var currentAttrValue = jQuery(this).attr('href');

	if(jQuery(e.target).is('.active')) {
		close_accordion_section();
	}else {
		close_accordion_section();
		// Add active class to section title
		jQuery(this).addClass('active');
		// Open up the hidden content panel
		jQuery(currentAttrValue).slideDown(300).addClass('open');

	}

	e.preventDefault();
});


let slickSliders = document.querySelectorAll('.mobfm-ad-slider');

function loadSlick(slickSlider, options) {
  $slickSlider = jQuery(slickSlider);
  $slickSlider.fadeIn(500, function() {
    $slickSlider.slick(options);
  }).delay(250).slick("slickPlay").slick('resize');
}

function onChange(elements, observer) {
  elements.forEach(element => {
    var elClasses = element.target.classList;

    if (element.intersectionRatio > 0) {
      var options = {};

      //if slider is loaded play it, if not pause it
      //load slider if initialized
      if(elClasses.contains("slick-initialized")) {
        jQuery(element.target).slick("slickPlay");
      }
      else {
      }
    }
    else {
      if(elClasses.contains("slick-initialized")) {
        jQuery(element.target).slick("slickPause");
      }
    }

  });
}




setTimeout(function(){
  if ('IntersectionObserver' in window) {
    // IntersectionObserver Supported
    let config = {
        root: null,
        rootMargin: '0px 0px 125px 0px',
        threshold: 0.0
    };

    let observer = new IntersectionObserver(onChange, config);
    slickSliders.forEach(slider => observer.observe(slider));

  } else {
    // IntersectionObserver NOT Supported
    slickSliders.forEach(slickSlider => loadSlick(slickSlider));
  }
}, 300);


//Bootstrap Accessibility
// On dropdown open
jQuery(document).on('shown.bs.dropdown', function(event) {
  var dropdown = jQuery(event.target);

  // Set aria-expanded to true
  dropdown.find('.dropdown-menu').attr('aria-expanded', true);

  // Set focus on the first link in the dropdown
  setTimeout(function() {
    dropdown.find('.dropdown-menu li:first-child a').focus();
  }, 10);
});
// On dropdown close
jQuery(document).on('hidden.bs.dropdown', function(event) {
 var dropdown = jQuery(event.target);

 // Set aria-expanded to false
 dropdown.find('.dropdown-menu').attr('aria-expanded', false);

 // Set focus back to dropdown toggle
 dropdown.find('.dropdown-toggle').focus();
});

if(location.hash) {
  //history.replaceState({}, document.title, location.href.split('#')[0]);
}
//Lazy Load all types of stuff
//https://stackoverflow.com/questions/52460010/how-to-call-different-functions-for-different-targets-using-an-intersection-obse





