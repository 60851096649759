// Home JS

//Check if is Mobile
var isMobile = false; //initiate as false
// device detection
if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
  || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) { // jshint ignore:line
  isMobile = true;
}

//----------------------------------------------
//----------------------------------------------
// Home search show / hide
//----------------------------------------------
//----------------------------------------------
function openSearchHome() {
  var home_search_close = jQuery('.mobfm-home-header-search .icon-close');
  var home_search = jQuery('.mobfm-home-header-search');
  var home_search_input = jQuery('.mobfm-home-header-search input');
  var home_search_button = jQuery('.mobfm-home-header-search .btn-secondary');
  var nav_search_icon = jQuery('.mobfm-nav-icons .icon-search');

  home_search_close.click(function () {
    home_search.slideUp(250);
  });

  nav_search_icon.click(function () {
    home_search.slideDown(250, function () {
      home_search_input.focus();
    });
  });
}


function videoClasses() {
  //https://stackoverflow.com/questions/20613365/i-cant-autoplay-vimeo-video-mobile
  /*
  if (jQuery(window).width() < 1000) {
    var mobile_video = "https://player.vimeo.com/video/637455880?h=c906741fcb&background=1&autoplay=1&loop=1&byline=0&title=0&muted=1";
    jQuery(".mob-video-wrapper .mob-video-iframe").attr("src", mobile_video);
  }
  */

  var div = document.querySelector('#mob-video-player');
  var player = new Vimeo.Player(div, {
    id: 868057468,
    speed: 1,
    controls: 0,
    autoplay: 1,
    muted: 1,
    loop: 1,
    title: 0,
    background: 1,
    byline: 0,
    dnt: true
  });

  console.log(player);

  jQuery(".mob-video-wrapper").addClass("loaded");

  var onPlay = function(data){

  };
  player.on('play', onPlay);

  //const iframe = document.querySelector('.mob-video-wrapper iframe');
  //const player = new Vimeo.Player(iframe);

  jQuery(".icon-pause").on("click", function (e) {
    e.preventDefault();
    if(jQuery(this).hasClass("paused")) {
      player.setCurrentTime(0);
      player.play();
      jQuery(this).removeClass("paused");
      jQuery(this).find("span").text("pause");
    }
    else {
      player.pause();
      jQuery(this).addClass("paused");
      jQuery(this).find("span").text("play");
    }
  });

  var onBuffered = function(data) {
    player.setCurrentTime(0);
    player.play();
  };
  player.on('bufferend', onBuffered);

}


function scrollToAnchor(aid) {
  var aTag = jQuery("a[name='" + aid + "']");
  jQuery('html,body').animate(
    {
      scrollTop: aTag.offset().top
    },
    { duration: 1000 }
  );
}

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 100;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

jQuery(function ($) {

  if(!isMobile) {
    videoClasses();
  }

  ////////////////////////////////////////////////////
  ///////////// Newsletter Signup ////////////////////
  ////////////////////////////////////////////////////
  var email_modal = $("#emailModal");
  let email_form = document.querySelector(".mob-home-newsletter");
  email_form.addEventListener("submit", function (e) {
    e.preventDefault(); // This prevents the window from reloading

    let formdata = new FormData(this);
    let input = formdata.get("email");

    if(input === "" || input === null) {
      $(".mob-error-message").fadeIn(500);
    }
    else {
      var mailchimpform = $(this);
      $.ajax({
        url: mailchimpform.attr('action'),
        type: 'POST',
        data: mailchimpform.serialize(),
        success: function (response) {
          if (response !== 'false') {
            $('.mob-default-message').fadeOut(500, function() {
              $('.mob-error-message').fadeOut(200);
              $('.mob-home-newsletter').fadeOut(200);
              $('.mob-success-message').fadeIn(500);
            });
            push_data = {
              'event': 'Home Newsletter',
              'label': "Sign Up",
              'action': 'Click'
            };
            window.dataLayer = window.dataLayer || [];
            dataLayer.push(push_data);
            Cookies.set('mob-ns-2024', '1', { expires: 3 });
            setTimeout(function () { email_modal.removeClass("scroll-in"); }, 15000);
          }
          else {
            $(".mob-error-message").fadeOut(200);
            $('.mob-fail-message').fadeIn(500);
            console.log(response);
          }
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(textStatus, errorThrown);
        }
      });
      return false;
    }


  });

  email_modal.on("click", ".icon-close", function () {
    Cookies.set('mob-ns-2024', '1', { expires: 4 });
    email_modal.removeClass("scroll-in");
  });


  if (jQuery(window).width() > 810 && isMobile) {
      //for closing modal

  }//> 769
  else {
    //var alert_height = $('.mobfm-alert-inner-container').outerHeight();
    //var header_height = $('header').outerHeight();
    //var slider_height = $(window).height() - alert_height - header_height + 15;
    //$('.mob-slider-mobile-show').css("height", slider_height);
  }//end 810


  //home slick slider
$('.header-slider').slick({
    slidesToShow: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000,
    dots: false,
    fade: true,
    infinite: true,
    speed: 800,
    cssEase: 'ease-in-out',
    useTransform: true,
    focusOnSelect: true,
    pauseOnHover:false,
    adaptiveHeight: false,
    responsive: [{
      breakpoint: 1000,
      settings: {
        dots: false,
      }
    }],
  });

  //mobile menu
  $(document).mouseup(function(e){
    var container = $(".sw-mobile-menu");
    if(!container.is(e.target) && container.has(e.target).length === 0){
        $(".sw-mobile-menu, .sw-mobile-sub-menu, .sw-mobile-bg, body").removeClass('active');
    }
  });

});//document.ready


var $document = jQuery(document);

$document.scroll(function() {
  setTimeout(() => {
    //console.log("Delayed for 5 seconds.");
    if (!Cookies.get('mob-ns-2024')) {
      jQuery("#emailModal").addClass("scroll-in");
    }
  }, 10000);

});


var swiper_talent = new Swiper(".mob-swiper-talent", {
  effect: "coverflow",
  speed: 1000,
  grabCursor: true,
  loop: true,
  centeredSlides: true,
  loopAdditionalSlides: 100,
  initialSlide: 9,
  lazy: true,
  preventClicks: false,
  preventClicksPropagation: false,
  slidesPerView:"auto",
  coverflowEffect: {
    rotate: 0,
    stretch:80,
    depth: 200,
    modifier: 1,
    slideShadows: false,
  },
  navigation: {
    nextEl: ".mob-talent-button-next",
    prevEl: ".mob-talent-button-prev",
  },
  breakpoints: {
    // when window width is >= 320px
    375: {
      effect: "",
      spaceBetween: "10px",
    },
    // when window width is >= 480px
    700: {


    },
    // when window width is >= 640px
    1200: {
      spaceBetween: "-35px",
    }
  }
});



var swiper = new Swiper(".mob-swiper-featured-events", {
  spaceBetween: "-10px",
  speed: 1000,
  grabCursor: true,
  slidesPerView:"auto",
  centeredSlides: true,
  loop: true,
  loopAdditionalSlides: 100,
  navigation: {
    nextEl: ".mob-featured-event-button-next",
    prevEl: ".mob-featured-event-button-prev",
  },
  breakpoints: {
    // when window width is >= 320px
    375: {
      spaceBetween: "10px",

    },
    // when window width is >= 480px
    700: {
      spaceBetween: "25px",

    },
    // when window width is >= 640px
    1200: {
      spaceBetween: "25px",
    }
  }
});


/*
const swiperSlides = document.getElementsByClassName('swiper-slide');

swiper.on('slideChange', function() {
  const otherSlides = swiperSlides;
  for (let index = 0; index < swiperSlides.length; index++) {
    const element = swiperSlides[index];
    element.getElementsByTagName('a')[0].style.display = 'none';
  }

  const linkElemCurrentSlide = swiperSlides[swiper.realIndex].getElementsByTagName('a');
  linkElemCurrentSlide[0].style.display = 'block';
});



jQuery('.mySwiper .swiper-slide a').on('click touchstart', function(e) {
 e.preventDefault();
});
*/
